@import (css)
	url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");

// Theme Variables
@import "~antd/lib/style/themes/default.less";
@import "variables.less";
@import "mixin.less";

// Third Party Stylesheets
@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry

// Common and Overrides Stylesheets
@import "./utils/utilities.less";
@import "./common.less";
@import "./override.less";

// Layout Stylesheets
@import "./layout/header.less";
@import "./layout/app-header.less";
@import "./layout/app-sidebar.less";

// Utils Stylesheets
@import "./utils/container.less";

// Sections Stylesheets
@import "./sections/hero-section.less";

// Views Stylesheets
@import "./views/auth.less";

@import "_formulize.less";