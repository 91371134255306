@import '../variables.less';

.register__section,
.login__section {
	// min-height: calc(~"100vh - @{layout-header-height}");
	min-height: calc(~"80vh - @{layout-header-height}");
	background-color: @white;
	display: flex;
	justify-content: center;
	align-items: center;
	padding:0px;
	.card__title{
		padding: 0px 0px 20px 0px;
	}
	.ant-card-bordered{
		border:none;	
		.ant-card-body{
			padding:0px 0px;
		}
		.formWrap{
			max-width: 400px;
 	   min-width: 400px;
      margin: 0 auto;
	    h2{
			padding:0px 0px 0px 0px;
		}
	
		form{

			.reminderMsg {
				position: relative;
				background-color: rgba(236, 159, 88, 0.1);
				padding:16px;
				display: flex;
				flex-wrap: wrap;
				gap: 12px;
				border-radius: 12px;
				margin-bottom:10px;
			
				>span {
					padding-left: 30px;
					font-size: 13px;
					color: #ec9f58;
					font-weight: 600;
				
			
			
				}
			
				i {
					background-color:#ec9f58;
					width: 18px;
					height: 18px;
					border-radius: 50%;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					position: absolute;
					left: 20px;
					top:19px;
			
					span {
						color:#fff;
					}
				}
			}
			.text-center{
					padding:20px;
					a{
						cursor: pointer;
						font-weight:600;
					}
			}
			.ant-form-item{
				margin-bottom:10px;
				.ant-form-item-control-input{
					min-height: 50px;
					
				}
				.ant-form-item-explain-error {
					padding-left:14px;
				}
				.ant-input-group{
				.ant-input{
					background-color: @input-field-color;
					border:none;
					border-radius:12px;
					// padding:11px 11px;
					padding:18px 20px 4px 14px;
					// min-height:50px;
					&:hover{
						background-color: @input-field-color;
					}
					&:focus
						{
					box-shadow: 0 0 0 1px @primary-color;

						 }
						 span.ant-input-group-addon {
							background: no-repeat;
							color: @font-color;
							border: 0;
							position: absolute;
							z-index: 9;
							transform-origin: 0 0;
							transition: @transition;
							top: 50%;
							transform: translate(0, -50%);
							margin: -5px 0px;
					
						}
					
						input:focus~span.ant-input-group-addon {
							transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
							top: 20px;
						}	
						&::placeholder{
							color: transparent;
						}
						  }
						  
					
						}
						.ant-input-password {
							.ant-input-affix-wrapper-focused{
								~.ant-input-group-addon{
									transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
									top: 20px;
									// font-size: 12px;
							}
							}
							input:focus~span.ant-input-group-addon {
							transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
							top: 20px;
						}
						  }
				   .ant-input-affix-wrapper{
					background-color: @input-field-color;
					border:none;
					border-radius:12px;
					padding: 0px 0px;
					width:100%;
					.ant-input-affix-wrapper-lg{
						padding: 1.5px 11px;
					}
					&:focus
					{
					box-shadow: 0 0 0 1px @primary-color;
					
				
					}
					
					.ant-input-suffix {
						margin-right:10px; 
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: 10px;
						z-index: 9;
					}
					&::placeholder{
						color: transparent;
					}
					
					}
					.ant-input-affix-wrapper-focused{
						box-shadow: 0 0 0 1px @primary-color;
						
					}

		
		}
		.passwordField{
			.ant-form-item-extra{
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		padding: 10px 0px;
		a{
			font-weight: 600;
			  font-size: 14px;
			  color: @primary-color;
			  font-family:@fontFamily;
			  transition:@transition;
			  cursor: pointer;
		}
			}
			&:hover{
				a{
					color:#2d3cbe;
				}
			}
		}
      
	.width-150 {
				width: 100%;
				max-width: 100%;
				background: @primary-color;
				border-radius:10px;
				transition:0.50s all linear;
				min-height:52px;
				&:hover{
					background: @primary-color;
				}
				span{
				font-family:@fontFamily;
                font-size: 16px;
                font-weight: 600;


				}
			}
		}
        @media screen and (max-width:600px){
			max-width:100%;
			min-width:80%;
			width:80%;
		}

	}
		}

}



