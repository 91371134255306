// ------------------------font import
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;600;700;900&display=swap');
// ------------------------add variable file
@import 'variables.less';

.fullscreen__spinner__wrapper {
	.Flex();
	justify-content: center;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.main__page__wrapper {
	.ag-grid-wrapper {
		padding: @padding;
	}

	// -----------------------------------------------------comman main layout class-----------------------------------------------------------
	.ant-page-header-heading-left {
		.ant-page-header-heading-title {
			.mediumFont();
			font-size: 20px;

		}
	}

	.ant-modal {
		.ant-form-item {
			.ant-form-item-control-input {
				min-height: 50px;
			}

			.ant-input {
				font-size: 14px;
				.mediumFont();
			}
		}
	}

	.breadcrumsHeading {
		.ant-page-header {
			.ant-page-header-heading {
				// padding: 20px 20px 20px 20px !important;
				padding: 28px 24px 28px 24px;
				box-shadow: 0px 0px 40px 4px rgb(40 43 54 /0.08);

				.ant-page-header-heading-left {
					margin: -4px 30px;
				}

			}
		}

	}

	.ant-form-item-control-input-content {
		padding: 0px 2px;

		span.ant-input-group-addon {
			background: no-repeat;
			color: @font-color;
			border: 0;
			position: absolute;
			z-index: 9;
			transform-origin: 0 0;
			transition: @transition;
			top: 50%;
			transform: translate(0, -50%);
			// margin: -5px 12px;

			padding-left: 14px;
		}

		input {

			&:focus~span.ant-input-group-addon {
				transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
				top: 25px;
				font-size: 12px;
			}
		}
	}


	// -----------------------------------------------------search bar css-----------------------------------------------
	.searchBar {
		width: 400px;
		margin-top: 15px;

		.ant-input-affix-wrapper {
			padding: 10px 15px;
			border: none;
			color: @font-color;
			font-size: 14px;
			font-family: @fontFamily;
			font-weight: 400;
			border-radius: 10px;
			box-shadow: 0px 0px 40px 4px rgb(40 43 54 /0.08);
			// min-height:50px;
		}

		@media screen and (max-width:1024px) {
			min-width: 300px;
			max-width: 300px;
		}

		@media screen and (max-width:991px) {
			min-width: 200px;
			max-width: 200px;
		}

		@media screen and (max-width:991px) {
			min-width: 200px;
			max-width: 200px;
		}

		@media screen and (max-width:850px) {
			max-width: 350px;

		}

		@media screen and (max-width:600px) {
			max-width: 400px;

		}

	}

	.ant-col {
		.ant-space {
			padding-top: 20px;
			gap: 14px !important;
		}
	}

	// -------------------------------------------------------------searchbar css ends here-----------------------------------------------------

	// ----------------------------------app header bar css starts from here-----------------------------------------------------------------------
	.ant-layout-header {
		height: 0px;

		.appheader__left {
			svg {
				position: absolute;
				top: 30px;
				z-index: 9;
				left: 24px;
			}
		}
	}

	.ant-page-header-heading-left {
		margin: 4px 35px;
	}

	&.has__header {
		.main__page__content {
			padding-top: @layout-header-height;

		}
	}

	&.has__appsidebar {
		.main__page__content {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			background-color: @white;
			padding-left: 200px;
			transition: all 0.2s;


			.ant-page-header-ghost {
				padding: 0px 0px;

				// .ant-page-header-heading {
				// 	padding: 28px 24px 0px 24px;
				// }

			}

			// ----------------------------------------------------------------app header css ends here-----------------------------------------



			//--------------------------------------------------------ag grid css starts from here--------------------------------------------
			.ag-header-container {
				padding: 0px 25px;

			}



			//    ---------------ag grid center content-------------------------------------------------------


			//  .ag-header-cell-label{
			// 	justify-content: center;
			//  }
			//  .ag-cell{
			// 	text-align: center;
			//  }
			.ag-header {
				background-color: @white;
				border-bottom: none;

				.ag-header-cell-resize {
					&:after {
						display: none;
					}
				}

				.ag-header-cell-text {
					.semiMediumFont();
					font-size: 14px;
					color: @font-color;
				}

				.ag-header-cell {
					border-top-color: @border-color !important;

				}

			}

			.ag-center-cols-viewport {
				padding: 0px 25px;
			}

			.ag-cell {
				.semiMediumFont();
				font-size: 14px;

				.gridButton {
					// .Flex();
					// justify-content: flex-end;
					// flex-wrap: wrap;
					margin-left: -16px;

					button {
						padding: 4px 9px;
						font-size: 20px;
						line-height: 1;
					}

					.edit {
						svg {
							transition: @transition;

							&:hover path {
								fill: @primary-color;
							}
						}
					}
				}

				.deleteBtn {
					svg {
						transition: @transition;

						&:hover path {
							fill: #D88080;
						}
					}
				}

				.TagButton {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: -30px;

					svg {
						transition: @transition;

						&:hover path {
							fill: @primary-color;
						}

					}

					.teamEdit {
						line-height: 50px;
					}

					.assignClientBtn {
						margin-top: 8px;
						border: none;
						box-shadow: none;
						background-color: transparent;

						span {
							color: #676A78;
							.mediumFont();
							font-size: 14px;
							transition: @transition;
						}

						&:hover {
							span {
								color: @primary-color;
							}
						}
					}

				}



			}
		}

		.ag-cell-range-selected {

			border-color: @primary-color;
		}

		.ag-paging-panel {
			justify-content: center;

			@media screen and (max-width:600px) {
				display: block;
			}

			.ag-paging-row-summary-panel,
			.ag-paging-page-summary-panel {
				@media screen and (max-width:600px) {
					margin: 2px 20px;
					display: flex;
					justify-content: space-evenly;
					align-items: center;
				}
			}
		}

		.ag-body-viewport {
			box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);

			// .ag-group-contracted{
			svg {
				transition: @transition;

				&:hover {
					fill: @primary-color;
					stroke: @primary-color;
				}
			}


		}

		//------------------------------------------------------------ag grid css ends here------------------------------------------------



		// -------------------------------------------------------metrics layout css started from here----------------------------------------

		.mainLayoutHeader {
			.app__container {

				margin-top: 34px;
			}

			.ant-page-header-heading {
				padding: 28px 24px 0px 20px;
			}

			.ant-page-header-heading-left {
				margin: -4px 30px;
			}

			.ant-col {
				@media screen and (max-width:768px) {
					display: contents;
				}
			}

			.metricsButton {
				justify-content: end;

				.ant-col {
					.ant-space {

						@media screen and (max-width:550px) {
							display: block;
							padding-top: 10px;
						}

						.ant-space-item {
							@media screen and (max-width:550px) {

								margin-top: 10px;
							}
						}
					}
				}

				@media screen and (max-width:550px) {
					justify-content: start;
					margin-left: 24px;
				}
			}

			.metricsBoxgridButton {
				line-height: 60px;

				// margin-left:-40px;
				button {
					padding: 4px 9px;
					font-size: 20px;

				}

				.editBtn {
					svg {
						transition: @transition;

						&:hover path {
							fill: @primary-color;
						}
					}
				}

				.addBtn {
					svg {
						transition: @transition;

						&:hover path {
							stroke: @primary-color;
						}
					}
				}
			}

			.ag-paging-panel {
				margin-bottom: 25px;
			}
		}

		.ant-tabs {
			.ant-tabs-nav-wrap {
				padding: 0px 48px;
			}

			.ant-tabs-nav {
				margin-bottom: 0px;
				box-shadow: 0px 24px 54px 0px #1A243714;

				&::before {
					border: none;
				}

			}

			.ant-tabs-tab {
				color: @font-color;
				font-family: @fontFamily;
				font-weight: 600;
				font-size: 16px;
				padding: 0px 0px 20px 0px;
			}

			.ant-tabs-tab-active {
				.ant-tabs-tab-btn {
					color: @primary-color;
				}
			}
		}

		.ant-tabs-ink-bar {
			background: @primary-color;
			border-top-left-radius: 25px;
			border-top-right-radius: 25px;
			height: 3px;

		}

		.ant-row-space-between {
			padding: @padding;
			align-items: center;
		}

		.ant-tabs-content-holder {
			margin-top: 15px;
		}


	}

	.ant-layout-sider-collapsed~.main__page__content {
		padding-left: 0px;
	}

	.ant-layout-sider-collapsed {
		flex: 0 0 0px !important;
		max-width: 0px !important;
		min-width: 0px !important;
		width: 0px !important;

		.logo__wrapper:after {
			content: none;
		}
	}
}


.main__app__content {
	flex-wrap: nowrap;
	display: flex;
	flex-direction: column;

	.app__container {
		display: flex;
		flex-direction: column;
		// padding-left: @gutter;
		// padding-right: @gutter;
		padding-bottom: @gutter;
		margin-top: 15px;

		&__card {
			height: 100%;
		}
	}

}


.ag-grid-wrapper {
	height: 100%;
	min-height: 400px;
}

.logo__wrapper {
	height: @logo-height;
	// display: block;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	margin-top: 40px;
	z-index: 9;
	cursor: pointer;

	img {
		// max-height: 100%;
		max-width: 100%;
		width: 260px;
		// margin-left: 20%;

		&+span {
			margin-left: @logo-space;
		}
	}

	span {
		white-space: nowrap;
		font-size: @logo-text-size;
		font-weight: bold;
		color: @text-color;
	}
}

.section {
	padding: @section-padding;
}

.section__big__title {
	display: block;
	font-weight: bold;
	font-size: 56px;
	color: @text-color;
}

.section__subtitle {
	display: block;
	font-size: 24px;
}

.ant-card {
	.card__title {
		font-weight: 600;
		font-family: @fontFamily;
		padding: 0px 0px 30px 8px;
	}
}

// -------------------------------------------------------------------rename modal css starts--------------------------------------------------------------
.renameModal {
	.ant-modal-content {
		.ant-modal-header {
			padding: 10px 24px 0px 12px;
		}

		.ant-modal-body {

			// padding:0px 24px 0px 24px;
			.ant-form {
				margin: 0px 0px;
			}
		}

		.ant-modal-footer {
			padding: 0px 24px 10px 24px;
		}
	}
}





// ---------------------------------------------------------------metrics Buttons css starts-------------------------------------------------------------------

.editButton {
	.Flex();
	justify-content: space-between;
	background: rgba(74, 92, 251, 0.1);
	border-radius: 10px;
	border: none;
	transition: @transition;
	padding: 8px 16px;
	min-height: 40px;

	span {
		.mediumFont();
		color: @primary-color;
		// padding:11px 16px;

		@media screen and (max-width:600px) {
			font-size: 13px;
			padding: 2px;
		}

	}

	svg {
		@media screen and (max-width:600px) {
			width: 20px;
		}
	}

	&:hover {
		background-color: #001aff1a;
	}

	@media screen and (max-width:600px) {
		padding: 20px 6px;
	}

	&:focus {
		background: rgba(74, 92, 251, 0.1);
	}

	img {
		width: 20px;
		margin-left: 13px;
	}
}

.ag-grid-height {
	height: 400px !important;
	margin-top: 20px !important;

	.ag-root-wrapper {
		border: none;
		box-shadow: 0px 4px 40px 4px rgb(40 43 54 /0.08);

	}

}

// --------------------------------------------------for bottom page number of metrics-----------------------------------

.metricGridLayout {
	.ag-paging-panel {
		margin-bottom: 25px;
	}
}

.ag-theme-alpine {
	.ag-paging-panel {
		border-top: none;
	}

	.ag-paging-button {
		cursor: pointer !important;
	}

	.ag-group-expanded {

		margin-top: 6px;

	}

	.ag-group-contracted {
		margin-top: 6px;
	}
}



// -------------------------------------------------------------add metric css starts from here----------------------------------------------------------------

.ant-modal {
	.ant-modal-content {
		border-radius: 10px;
		// padding:24px;
		padding: 12px;

		.ant-modal-header {
			border: none;
			padding: 12px 12px 20px 12px;

			.ant-modal-title {
				.mediumFont();
				font-size: 16px;
				color: #000;
			}
		}

		.ant-modal-body {
			padding: 0px 12px;

			.ant-segmented {
				border-radius: 12px;
				background: @input-field-color;
				width: 100%;

				.ant-segmented-group {
					width: 100%;
					padding: 2px;

					.ant-segmented-item {
						padding: 4px 0px;
						width: 50%;
					}

					.ant-segmented-item-selected {
						border-radius: 10px;

						.ant-segmented-item-label {
							color: @primary-color;
						}
					}
				}

				.ant-segmented-item-label {
					.mediumFont();
					font-size: 14px;
					color: @font-color;
				}
			}

			.ant-form-item-explain-error {
				padding-left: 14px;
			}
		}

		.metricsModal {
			.ant-modal-body {
				padding: 5px 24px !important;

				.ant-form {
					padding: 0px 0px 20px 0px;

					.ant-list-item {
						padding: 12px 0px 0px 15px !important;

						.ant-list-item-action {
							li {
								padding: 0px 0px;

								.addButton {
									margin-top: 0px;
									background: transparent;
									border: none;
									box-shadow: none;
									color: #676878;
									transition: @transition;

									span {
										font-family: @fontFamily;
										font-size: 16px;
										font-weight: 600;
									}

									&:hover {
										span {
											color: @primary-color;
										}
									}
								}
							}
						}

					}

				}
			}

		}

		.ant-form {
			margin: 20px 0px 20px 0px;

			// .ant-form-item {
			// 	// padding-top:10px !important;
			// 	// padding-left:10px;
			// 	margin-bottom: 0px;

			// 	.ant-form-item-control-input {
			// 		min-height: 50px;
			// 	}

			// 	.ant-form-item-row {
			// 		position: relative;
			// 	}

			// 	.ant-form-item-label {
			// 		position: absolute;
			// 		z-index: 9;
			// 		// top: 50%;
			// 		// left: 20px;
			// 		top: 20px;
			// 		left: 16px;
			// 		transform-origin: 0 0;
			// 		transition: opacity .1s ease-in-out, transform .1s ease-in-out;
			// 	}

			// 	.ant-input {
			// 		border-radius: 12px;
			// 		background: @input-field-color;
			// 		border: none;
			// 		padding: 20px 20px 5px 16px;
			// 		.mediumFont();
			// 		font-size: 14px;

			// 		// min-height:50px;
			// 		&::placeholder {
			// 			color: transparent;

			// 		}

			// 		&:focus {
			// 			border-color: @primary-color;
			// 			box-shadow: 0 0 0 1px@primary-color;
			// 		}
			// 	}
			// }

			// .ant-form-item-label {
			// 	>label {
			// 		.semiMediumFont();
			// 		font-size: 14px;
			// 		color: @font-color;
			// 	}
			// }

			.MetricsCategory {

				.ant-form-item {
					position: relative;
					margin-bottom: 0px;

					.ant-form-item-label {
						padding: 0;
						position: absolute;
						left: 16px;
						top: 5px;
						z-index: 9;
						// .transition;

						label {
							font-size: 12px;
							color: @grey-color;
							font-weight: 400;
							font-family: @fontFamily;
							height: 100%;
						}
					}

					.ant-form-item-control-input {
						min-height: 50px;
					}

				}

				.ant-select-selector {
					background: @input-field-color;
					height: 50px;
					border-radius: 12px;
					border: none;

					.ant-select-selection-item {
						color: #000;
						font-weight: 600;
						padding: 7px 0px;
					}
				}

				.ant-select-arrow {
					svg {
						path {
							fill: @primary-color;
						}
					}
				}

				.ant-select-focused {
					border: 1px solid @primary-color;
					border-radius: 12px;
				}

				.ant-select-selection-placeholder {
					padding: 7px 0px;
				}

				.ant-form-item-extra {
					min-height: 24px;
					position: absolute;
					right: 15px;
					top: 25%;
					cursor: pointer;
				}

				.ant-select-selector {
					background-color: #f2f3f7 !important;
				}
			}

			.addCategory {
				.Flex();
				justify-content: flex-end;
				padding: 12px 7px 30px 0px;
				color: @font-color;
				transition: @transition;
				font-size: 16px;


				.mediumFont();

				&:hover {
					color: @primary-color;
				}

				span {
					color: @font-color;
					font-size: 16px;
					transition: @transition;
					cursor: pointer;
					.mediumFont();

					&:hover {
						color: @primary-color;
					}
				}
			}
		}


		.ant-modal-footer {
			padding: 0px 12px 12px 12px;
			border: none;

			.ant-btn {
				border: none;
				background-color: @primary-color;
				width: 100%;
				border-radius: 10px;
				height: 100%;
				padding: 16px 20px;

				span {
					.mediumFont();
					font-size: 16px;
					color: #fff;

					@media screen and (max-width:480px) {
						font-size: 13px;
					}
				}
			}
		}
	}
}



.deleteModal {
	.ant-modal-close {
		top: 11px;
		right: 10px;
	}

	.deleteSign {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 12px;
	}

	.deleteTitle {
		padding: 20px 0px 0px 0px;
		text-align: center;
		.mediumFont();
		font-size: 16px;
	}

	.ant-modal-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.deleteContent {
		// padding: 10px 40px 20px 40px;
		text-align: center;
		.semiMediumFont();
		font-size: 14px;
		// margin-top: -10px;
		// margin-top: -20px;
		margin-bottom: 20px;
	}

	.ant-modal-footer {

		.ant-btn.deleteBtn {
			background: #D88080;
			transition: @transition;

			&:hover {
				background-color: #ca4b4b;
			}

		}

		.ant-btn.cancelBtn {
			background-color: transparent;
			margin-left: 0px;
			color: @primary-color;

			span {
				color: @font-color;
				transition: @transition;
			}

			&:hover {
				span {
					color: @primary-color;
				}
			}
		}

	}
}

// -----------------------------------edit metrics calculation css starts from here------------------------------------------------------------


.editMetric {

	.ant-list-split {
		.ant-list-item {
			margin: 10px 0px 0px 0px;
			padding: 8px 0px 8px 16px;
			max-height: 50px;
			border-radius: 12px;
			background-color: @white;
		}
	}

	.ant-list-item-action {
		li {
			padding: 0px 0px;

			button {
				margin-top: 0px;
				background: transparent;
				border: none;
				box-shadow: none;
				color: #676878;
				transition: @transition;

				span {
					font-family: @fontFamily;
					font-size: 16px;
					font-weight: 600;
				}

				&:hover {
					span {
						color: @primary-color;
					}
				}
			}
		}
	}

	.editMetricCategory {
		.Flex();
		justify-content: flex-end;
		padding: 12px 0px 20px 0px;
		color: @font-color;
		transition: @transition;
		font-size: 16px;

		.mediumFont();

		&:hover {
			color: @primary-color;
		}

		span {
			color: @font-color;
			font-size: 16px;
			transition: @transition;
			cursor: pointer;
			.mediumFont();

			&:hover {
				color: @primary-color;
			}
		}
	}

	.listCategory {
		padding: 0px 10px 20px 10px;
		margin: 10px 0px 0px 0px;
		border-radius: 12px;
		background: #f2f3f7;

		.searchName {
			.ant-input-affix-wrapper {
				background-color: @white;

				input {
					background-color: @white;
				}
			}
		}
	}

	.ant-form-item-label {
		top: 3px !important;

		label {
			font-size: 12px !important;
		}
	}

	.ant-select-selector {
		padding: 10px 14px !important;
	}
}

// -----------------------------------------------------------metrics Box css starts from here---------------------------------------------

.metricsCollapse {
	padding: 20px;

	.ant-collapse {
		border: none;
		border-radius: 10px;
		box-shadow: 0px 0px 40px 4px rgb(40 43 54 /0.08);

		.ant-collapse-item {
			border-bottom: none;
		}

		.ant-collapse-content {
			border: none;
		}

		.ant-collapse-header {
			span {
				.mediumFont();
				font-size: 18px;
				color: #222325;
			}
		}
	}
}

.ant-modal {
	.searchName {
		padding-top: 20px;

		.ant-input-affix-wrapper {
			padding: 10px 15px;
			border: none;
			color: @font-color;
			font-size: 14px;
			font-family: @fontFamily;
			background-color: @input-field-color;
			border-radius: 10px;
			min-height: 50px;

			input {
				background-color: @input-field-color;
			}

		}

		.ant-input-affix-wrapper-focused {
			border: 1px solid@primary-color;
		}

		.ant-form-item {
			padding-top: 16px;
		}

		.ant-input-affix-wrapper-focused {
			box-shadow: none;
		}
	}
}

.searchCategory {
	padding-top: 20px;

	.ant-form-item {
		padding: 5px 0px;
	}
}

.newMetricBox {
	.ant-modal-close {
		top: 11px;
		right: 10px;
	}

	.searchName {
		padding-top: 0px;
	}

	.ant-modal-header {
		padding: 12px 12px 0px 12px !important;
	}

	.ant-modal-body {
		padding: 0px 12px;

		form {
			margin: 20px 0px 0px 0px !important;
		}
	}

	.searchbyCategory {
		padding-top: 20px;

		.ant-input-affix-wrapper {
			padding: 11px 20px;
			background-color: @input-field-color;
			border: none;
			border-radius: 12px;
			min-height: 50px;

			.ant-input {
				background-color: @input-field-color;
			}
		}

		.ant-input-affix-wrapper-focused {
			border: 1px solid @primary-color;
		}

	}

	.metricHeading {
		.semiMediumFont();
		font-size: 14px;
		color: @font-color;
		padding-bottom: 20px;
	}

	.ant-divider-horizontal {
		margin: 0px 0px 20px 0px;
	}

	.addButton {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0px;
	}



	.ant-list {
		max-height: 200px !important;
		overflow: auto;
		margin-top: 5px;
	}

	.ant-form-item {
		padding-top: 0px;
	}

	.ant-list-item {
		border-bottom: none;
		background: #f2f3f7;
		min-height: 50px;
		margin-top: 10px;
		padding: 0px 0px 0px 14px;
		border-radius: 10px;
		font-family: @fontFamily;
		font-size: 14px;
		font-weight: 600;
		word-break: break-all;
		margin-bottom: 20px;


		.ant-list-item-action {
			li {
				padding: 0px 0px;

				button {
					margin-top: 0px;
					background: transparent;
					border: none;
					box-shadow: none;
					color: #676878;
					transition: @transition;

					span {
						font-family: @fontFamily;
						font-size: 16px;
						font-weight: 600;


					}

					&:hover {
						span {
							color: @primary-color;
						}
					}
				}
			}
		}
	}

	.addButton {
		margin-top: 0px;
	}

	.ant-form-item-label {
		top: 3px !important;

		label {
			font-size: 12px !important;
		}
	}

	.ant-select-selector {
		padding: 10px 14px !important;
	}

}

.addMetric {
	width: 420px !important;

	.ant-modal-header {
		padding: 12px 12px 0px 12px !important;
	}

	.ant-modal-content {
		height: 500px;
	}

	.ant-modal-body {
		height: 360px;
		overflow: auto;
		border-top: none;

		.ant-form {
			margin: 10px 0px 10px 0px;
		}
	}

	.addButton {
		margin-top: 0px;
		background: transparent;
		border: none;
		box-shadow: none;
		color: #676878;
		transition: @transition;

		span {
			font-family: @fontFamily;
			font-size: 16px;
			font-weight: 600;
		}

		&:hover {
			span {
				color: @primary-color;
			}
		}
	}

	.ant-list-item {
		border-bottom: none;
		background: #f2f3f7;
		border-radius: 12px;
		margin: 10px 0px 0px 0px;
		padding: 8px 0px 8px 16px;
		max-height: 50px;
	}

	.listCategory {
		padding: 0px 10px 20px 10px;
		margin: 10px 0px 0px 0px;
		border-radius: 12px;
		box-shadow: 0px 0px 40px 4px rgb(40 43 54 /0.08);
	}

	.searchName {
		padding: 15px 0px;

		.ant-input-affix-wrapper {
			background-color: #f2f3f7;

			.ant-input {
				background-color: #f2f3f7;
			}
		}
	}

	.ant-modal-footer {
		padding: 12px !important;
	}

}

// .ant-form-item-control-input-content {
// 	padding: 0px 2px;

// 	span.ant-input-group-addon {
// 		background: no-repeat;
// 		color: @font-color;
// 		border: 0;
// 		position: absolute;
// 		z-index: 9;
// 		transform-origin: 0 0;
// 		transition: @transition;
// 		top: 50%;
// 		transform: translate(0, -50%);
// margin: -5px 12px;

// padding-left:14px;
// 	}

// 	input:focus~span.ant-input-group-addon {
// 		transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
// 		top: 25px;
// 	}
// }

// -----------------------------------------------------------metrics layout css ends here------------------------------------------------------

// -------------------------------------------------------------subscription css starts from here------------------------------------------------
.subscrptionLayout {
	.searchBar {
		margin-top: 0px;
	}

	.backTo {
		position: relative;

	}

	.ant-page-header-heading-left {
		margin: 2px 35px;
	}

	.app__container {
		margin-top: 30px !important;
	}

	.ant-card-extra {
		// .Flex();
		// justify-content: flex-end;
		// flex-wrap: wrap;
		padding: 25px 0px 0px 0px;

		@media screen and (max-width:600px) {
			justify-content: start;
			padding-top: 0px;
		}

		.ant-row-end {
			@media screen and (max-width:600px) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}
		}
	}

	.ant-card-bordered {
		border: none;
		box-shadow: 0px 0px 40px 4px rgb(40 43 54 /0.08);
		margin: 20px 24px 0px 24px;
		border-radius: 10px;
		.semiMediumFont();

		.ant-card-head {
			border: none;
		}

		.ant-card-head-wrapper {
			@media screen and (max-width:600px) {
				display: block;
			}
		}
	}

	.ant-card-body {
		padding: 0px 24px 20px 24px;

		.subscriptionContent {
			.Flex();
			justify-content: space-between;
			margin-top: -5px;

			@media screen and (max-width:600px) {
				display: block;
			}

			.subscriptionPera {
				width: 40%;
				.semiMediumFont();
				font-size: 14px;
				color: @font-color;
				padding-left: 7px;
				word-break: break-all;
			}

			.linkContent {

				// padding:12px 0px;
				.editLink {
					padding: 6px 14px;
					// margin-top: 26px;
					margin-right: 8px;

					span {
						padding-right: 8px;
						margin-right: -10px;
					}
				}

				.deactivateLink {
					// padding-top: 26px;
					// padding-bottom: 25px;
					padding: 6px 14px;
					margin-top: 23px;

					span {
						margin-right: -6px;
					}
				}

				button {
					font-size: 14px;
					border: none;
					.mediumFont();
					color: @font-color;
					transition: @transition;

					&:hover {
						span {
							color: @primary-color;
						}
					}

					&:focus {
						background-color: transparent;
						border-color: transparent;
						box-shadow: none;
					}
				}

				button {
					font-size: 14px;
					border: none;
					.mediumFont();
					color: @font-color;
					transition: @transition;

					&:hover {
						span {
							color: @primary-color;
						}
					}


				}

				@media screen and (max-width:600px) {
					.Flex();
					justify-content: space-between;
				}

				@media screen and (min-width:380px) {
					font-size: 14px;
				}

			}

		}

	}

	.ant-col {
		.subFees {
			padding-right: 20px;
			.mediumFont();
			font-size: 20px;
			color: #222325;

			@media screen and (max-width:800px) {
				font-size: 18px;
			}

			@media screen and (max-width:600px) {
				font-size: 20px;

			}
		}
	}

	.ant-tag {
		// color: #5BAF3D;
		// background: rgba(128, 203, 142, 0.2);
		border: none;
		border-radius: 6px;
		padding: 6px 14px;
		.mediumFont();
		font-size: 18px;
		min-height: 35px;

		@media screen and (max-width:800px) {
			font-size: 16px;
		}

		@media screen and (max-width:600px) {
			font-size: 18px;
		}
	}

	.active {
		background: #80CB8E33;
		color: #5BAF3D;
	}

	.deactive {
		background: #FF725E33;
		color: #D88080;

	}

	.ant-card-head-title {
		padding: 15px 0px 0px 6px;
		.semiMediumFont();
		font-size: 18px;
		color: #000;

		@media screen and (max-width:800px) {
			font-size: 16px;
		}

		@media screen and (max-width:600px) {
			font-size: 18px;
		}
	}

	.subscriptionBtn {
		padding-right: 25px;
	}

	.ant-space {
		padding-top: 0px !important;
		// padding-bottom: 20px;
	}


}

.subDelete {
	.Flex();
	justify-content: center;
	transition: @transition;
	padding-top: 25px;
	cursor: pointer;
	.semiMediumFont();

	color: @font-color;

	@media screen and (max-width:800px) {
		font-size: 14px;
	}

	svg {
		margin-right: 11px;
		width: 24px;
	}

	span {
		font-family: @fontFamily;
		font-weight: 600;
		font-size: 14px;
		transition: @transition;

	}

	&:hover {
		span {
			color: @primary-color;
		}


	}

}

.site-input-group-wrapper {
	padding-top: 20px;

	.ant-input-group.ant-input-group-compact {
		padding-top: 15px;
		.Flex();
		justify-content: space-around;

		.ant-select-selector {
			border: none;
			background-color: @input-field-color;
		}

		.ant-input-number {
			border: none;

			.ant-input-number-input {
				background-color: @input-field-color;
				border-radius: 10px;
				border: none;
			}
		}
	}
}

.editSubscription {
	.ant-modal-close {
		top: 11px;
		right: 10px;
	}

	.ant-modal-header {
		padding: 12px 24px 0px 12px !important;
	}

	.ant-modal-body {
		padding: 0px 12px !important;

		// .ant-form-item {
		// 	padding: 0px 7px 0px 0px;
		// }
		.ant-form {
			margin: 20px 0px 20px 0px;
		}
	}


	.ant-select-selector {
		height: 45px !important;
		padding: 10px 11px !important;
		border: none !important;
		border-radius: 10px !important;
		background-color: @input-field-color !important;
	}

	.ant-input-disabled {
		color: #000;
	}

	.ant-form-item-control-input-content {
		padding: 0px 2px;
	}

	.SubscriptionDescription {
		// padding: 0px 6px 0px 0px;

		textarea {
			margin-top: 20px;
			margin-bottom: 0px;
			border-radius: 12px;
			border: none;
			background: #F2F3F7;
			min-height: 50px;
			color: #000;
			font-weight: 600;
			padding-left: 16px;

			&:focus {
				border-color: #4A5CFB;
				box-shadow: 0 0 0 1px #4a5cfb;
			}
		}

	}

	.ant-form-item-label {
		label {
			font-size: 12px !important;
		}
	}
}

.ant-form-item-control-input-content {
	padding: 0px 2px;

	span.ant-input-group-addon {
		background: no-repeat;
		border: 0;
		position: absolute;
		z-index: 9;
		transform-origin: 0 0;
		transition: @transition;
		top: 50%;
		transform: translate(0, -50%);
		// margin: -5px 12px;

		padding-left: 14px;
		color: @font-color;
	}

	input:focus~span.ant-input-group-addon,
	input:not(:placeholder-shown)~span.ant-input-group-addon {
		transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
		top: 25px;
		font-size: 12px;
	}

	span.ant-input-group-addon {
		&:focus {
			transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
			top: 25px;
			font-size: 12px;
		}
	}


	// -----------------------------------------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------password label floating-----------------------------------------------------------------------------
	.ant-input-password-large.ant-input-group-wrapper-status-success .ant-input-group-addon,
	.ant-input-password-large.ant-input-group-wrapper-status-error .ant-input-group-addon {
		transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
		top: 25px;
		font-size: 12px;
	}
}



.subscriptionValue {
	padding-top: 10px;

	.ant-form-item-control-input-content {
		.ant-input {
			padding: 10px !important;
			.mediumFont();
			font-size: 14px;
		}
	}
}

// -----------------------------------------------------------------subscription css ends here--------------------------------------------------------------

// --------------------------------------------------------------Edit Metrics category Css starts from Here---------------------------------------------
.categoryModal {

	.ant-modal-close {
		top: 11px;
		right: 10px;
	}

	.ant-modal-header {
		padding: 12px 12px 0px 12px !important;
	}

	.ant-modal-body {
		padding: 0px 12px !important;

		.ant-form {
			margin: 10px 0px 0px 0px;

			.categoryTitle {

				font-family: @fontFamily;
				font-size: 14px;
				font-weight: 400;
				color: #676A78;
			}

			.ant-form-item-label {
				top: 16px !important;
			}
		}

		.ant-form-item-control-input {
			padding-top: 10px;
			min-height: 50px;

		}

		.ant-divider-horizontal {
			margin: 20px 0px;
		}

		.ant-input-affix-wrapper {
			padding: 9px 20px;
			margin: 5px 0px 10px 0px;
			min-height: 50px;
			border-radius: 10px;
			background-color: @input-field-color;
			border: none;
			min-height: 50px;

			.ant-input {
				background-color: @input-field-color;
				.mediumFont();
				font-size: 14px;

			}
		}
	}

	.ant-btn {
		margin-top: 10px;
	}

	.categoryForm {
		.ant-input-affix-wrapper-focused {
			border: 1px solid @primary-color;
			box-shadow: none;
		}

		.ant-list {
			max-height: 200px !important;
			// overflow: auto;
		}

		.ant-form-item {
			padding-top: 0px;
		}

		.ant-list-item {
			border-bottom: none;
			background: #f2f3f7;
			min-height: 50px;
			margin-top: 10px;
			padding: 0px 0px 0px 14px;
			border-radius: 10px;
			font-family: @fontFamily;
			font-size: 14px;
			font-weight: 600;
			word-break: break-all;


			.ant-list-item-action {
				li {
					padding: 0px 0px;

					button {
						margin-top: 0px;
						background: transparent;
						border: none;
						box-shadow: none;
						color: #676878;
						transition: @transition;

						span {
							font-family: @fontFamily;
							font-size: 16px;
							font-weight: 600;
						}

						&:hover {
							span {
								color: @primary-color;
							}
						}
					}
				}
			}
		}

		.addButton {
			margin-top: 0px;
		}

	}


	.ant-form-item-control-input-content {
		padding: 0px 2px;

		span.ant-input-group-addon {
			background: no-repeat;
			border: 0;
			position: absolute;
			z-index: 9;
			transform-origin: 0 0;
			transition: @transition;
			top: 50%;
			transform: translate(0, -50%);
			// margin: -5px 12px;

			padding-left: 14px;
			color: @font-color;
		}

		input:focus~span.ant-input-group-addon {
			transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
			top: 25px;
			font-size: 12px;
		}
	}
}

.addCategoryLayout {
	// padding-left: 200px;
	position: relative;

	.BackToHomeBtn {
		position: absolute;
		top: 24px;
		/* left: 162px; */
		margin-left: 20px;
		background: rgba(74, 92, 251, 0.1);
		border: none;
		width: 24px;
		height: 24px;
	}

	.ant-page-header-heading {
		padding: 20px 20px 25px 65px !important;

	}
}


.anticon-arrow-left {
	background: rgba(74, 92, 251, 0.1);
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	justify-content: center;
	color: @primary-color;
	transition: @transition;

	&:hover {
		color: rgb(3, 3, 75);
	}
}

.editCategory {
	.ant-page-header {
		.ant-page-header-heading {
			padding: 28px 0px 28px 28px !important;
			box-shadow: 0px 0px 40px 4px rgb(40 43 54 /0.08);

			.ant-page-header-heading-left {
				margin: -4px 60px !important;

				.ant-page-header-back {
					margin-right: 14px;
				}
			}
		}
	}

	.ant-page-header-heading-extra {
		margin: 0px 0px;

		.backTo {
			position: absolute;
			left: 60px;
			bottom: 38%;
			cursor: pointer;

			&:after {
				position: absolute;
				height: 24px;
				width: 24px;
				border-radius: 50%;
				background: rgba(74, 92, 251, 0.1);
				content: "";
				left: -8px;
			}

			@media screen and (max-width:534px) {
				bottom: 50%;
			}
		}
	}

}

//--------------------------------------------------------------Edit Metrics category Css ends  Here-----------------------------------------------------

// -------------------------------------------------------------referral css starts from here------------------------------------------------------------
.referralLayout {
	.ant-col {
		padding: 30px 24px 24px 24px;

		@media screen and (max-width:600px) {
			padding: @padding;
		}
	}

	// .gridButton {
	// 	.Flex();
	// 	justify-content: space-between !important;

	// }

	.ant-tag {
		padding: 4px 10px !important;
		font-size: 14px !important;
		margin-right: 0px !important;
	}

	.ant-checkbox-group {
		padding: @padding;
	}

	.ant-checkbox-group-item {
		margin-right: 40px;
	}

	.ant-checkbox+span {
		padding-left: 13.5px;
	}

	.ant-checkbox-wrapper {
		position: relative;

		&:after {
			position: absolute;
			content: "";
			width: 1px;
			height: 100%;
			background-color: #E5E7EC;
			right: -24px;
		}

		span {
			.semiMediumFont();
			font-size: 14px;
			color: #000;
		}
	}

	.ant-row {
		@media screen and (max-width:600px) {
			padding-top: 10px;

		}
	}
}

.changeStatusModal {
	.ant-modal-header {
		padding: 12px 12px 0px 12px !important;
	}


	.ant-form-item {
		// padding-top: 20px;
		margin-bottom: 0px;

		.ant-form-item-control-input {
			min-height: 50px;
		}



		.ant-select-selector {
			background: @input-field-color;
			height: 50px;
			border-radius: 12px;
			border: none;


			.ant-select-selection-item {
				color: #000;
				padding: 7px 0px;
				font-weight: 600;
			}
		}

		.ant-select-arrow {
			svg {
				path {
					fill: @primary-color;
				}
			}
		}

		.ant-select-focused {
			border: 1px solid @primary-color;
			border-radius: 12px;
		}

		.ant-select-selection-placeholder {
			padding: 7px 0px;
		}

		.ant-form-item-extra {
			min-height: 24px;
			position: absolute;
			right: 15px;
			top: 25%;
			cursor: pointer;
		}
	}
}









.ant-tag-blue {
	color: #4a5cfb;
	background: rgba(74, 92, 251, 0.2);
	border: none;
	font-size: 14px;
	.mediumFont();
}

.ant-tag-orange {
	background: rgba(236, 159, 88, 0.2);
	color: #EC9F58;
	border: none;
	font-size: 14px;
	.mediumFont();
}

.ant-tag-green {
	background: #80CB8E33;
	color: #5BAF3D;
	border: none;
	font-size: 14px;
	.mediumFont();
}

.ant-tag-red {
	background: #FF725E33;
	color: #D88080;
	border: none;
	font-size: 14px;
	.mediumFont();
}

//-----------------------------------------------------------referrals css ends here---------------------------------------------------------------------

// --------------------------------------------------------add team members css starts from here---------------------------------------------------------
.addTeamMember {
	.ant-modal-close {
		top: 11px;
		right: 10px;
	}

	.ant-modal-header {
		padding: 12px 24px 0px 12px !important;
	}

	.ant-modal-body {
		padding: @padding;

		form {
			margin: 20px 0px 0px 0px;
		}

		.ant-form-item {
			padding-top: 0px;
			margin-bottom: 0px;

			.ant-form-item-label {
				top: 25px !important;

				label {
					font-size: 12px;
				}
			}
		}

	}

	.ant-modal-footer {
		padding: 20px 12px;
	}

	.addTeamRow {
		.Flex();
		justify-content: space-between;

		.ant-form-item {
			margin: 0px 5px 0px 0px;
		}
	}

	.teamCategory {
		.ant-select-selector {
			background: @input-field-color;
			height: 50px;
			border-radius: 12px;
			border: none;

			.ant-select-selection-item {
				color: #000;
				padding: 7px 0px;
			}
		}

		.ant-form-item-label {
			top: 25px !important;

			label {
				font-size: 12px !important;
			}
		}

		.ant-select-selector {
			padding: 10px 14px;
		}

		// .ant-select-arrow {
		// 	svg {
		// 		path {
		// 			fill: @primary-color;
		// 		}
		// 	}
		// }

		.ant-select-focused {
			border: 1px solid @primary-color;
			border-radius: 12px;
			// .ant-select-arrow {
			// 	transform: rotate(180deg);
			// }

		}

		.ant-select-selection-placeholder {
			padding: 7px 0px;
		}

		.ant-form-item-extra {
			min-height: 24px;
			position: absolute;
			right: 15px;
			top: 47%;
			cursor: pointer;
		}
	}

	.ant-form-item-control-input-content {
		padding: 0px 2px;

		.ant-input {
			.mediumFont();
			font-size: 14px;

			span.ant-input-group-addon {
				background: no-repeat;
				border: 0;
				position: absolute;
				z-index: 9;
				transform-origin: 0 0;
				transition: @transition;
				top: 60%;
				transform: translate(0, -50%);
				// margin: -5px 12px;

				padding-left: 14px;
				color: @font-color;
			}

			input {
				&:focus~span.ant-input-group-addon {
					transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
					top: 20px;
					font-size: 12px;
				}
			}
		}
	}


	.ant-form-item-control-input {
		padding: 20px 0px 0px 0px;
		min-height: 50px;
	}

	.ant-upload-drag {
		background: @input-field-color;
		transition: @transition;
		border-radius: 8px;

		&:hover {
			border-color: @primary-color !important;
		}

		.ant-upload-drag-icon {
			margin-bottom: 17px !important;
			padding: 20px 0px 0px 0px;
		}

		.ant-upload-hint {
			padding-bottom: 10px;
		}
	}

	.draggerBackground {
		background: @input-field-color;
		padding: 10px;
		border-radius: 12px;

		.ant-form-item {
			margin-bottom: 0;
		}

		.ant-form-item-control-input {
			padding: 0px 0px;
		}
	}

	.numberDropDown {
		position: absolute;
		left: 14px;
		bottom: -16px;
	}

	.ant-upload-list-picture-card {
		display: flex;
		align-items: center;
		justify-content: center;

		// margin-top: 10px;
		.ant-upload-list-item {
			border: 1px dashed #BABECB;
		}

		.ant-upload-list-picture-card-container {
			margin: 15px 0px 15px 0px;
		}
	}

	.editImage {
		position: absolute;
		top: 33px;
		left: 150px;

		// 	display: flex;
		// align-items: center;
		// justify-content: center;
		button {
			background-color: transparent;
			border: none;
			transition: @transition;

			&:hover {
				svg {
					path {
						fill: @primary-color;
					}
				}
			}
		}
	}

	.PhoneInput {
		border-radius: 12px;
		background: #F2F3F7;
		border: none;
		/* padding: 20px 20px 5px 16px; */
		font-family: 'Source Sans Pro';
		font-weight: 600;
		font-size: 14px;
		height: 50px;

		.PhoneInputCountryIcon {
			margin-left: 14px;
		}

		.PhoneInputInput {
			background: #f2f3f7;
			padding-right: 10px;
			border: none;
			height: 40px;
			outline: 0;
			font-weight: 600;
		}

	}

	.PhoneInput--focus {
		border: 1px solid @primary-color;
	}

	.ant-select-selector {
		background-color: #f2f3f7 !important;
	}
}

.onPreview {

	// .ant-modal-content{
	// 	max-width:422px;
	// }
	.ant-modal-close {
		top: 10px;
		right: 11px;
	}

	.ant-modal-body {
		padding: 0px 12px 12px 12px !important;
	}
}

.previewImg {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ant-upload-list-item-actions {
	span {
		margin: 0 30px 0px 5px;
	}
}


.teamBtn {
	padding-right: 24px;

	svg {
		margin-left: 13px;
	}
}

// ---------------------------------------------assign member css starts from here---------------------------------------------------------------
.assignMember {
	.ant-row-end {
		padding: 29px 0px 19px 0px;
		.mediumFont();
		color: @font-color;
		font-size: 14px;
		cursor: pointer;
		transition: @transition;

		&:hover {
			color: @primary-color;
		}
	}

	.ant-modal-body {
		padding: 0px 24px;
	}

	.ant-input-affix-wrapper {
		background-color: @white;
		.mediumFont();
		color: @font-color;
		font-size: 14px;
		border: none;
		padding: 11px;
		border-radius: 12px;
		min-height: 50px;
		box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);

		.ant-input {
			background-color: @white;
			font-weight: 600;
		}
	}

	.ant-input-affix-wrapper-focused {
		border: 1px solid @primary-color;
	}

	.ant-checkbox-wrapper {
		position: relative;
		word-break: break-all;

		.ant-checkbox {
			position: absolute;
			right: 0px;
			top: 50%;
		}
	}

	.ant-modal-close {
		top: 11px;
		right: 10px;
	}
}

.checkListMember {
	height: 400px;
	overflow: scroll;

	.ant-row {
		flex-direction: column;
		background: @input-field-color;
		padding: 10px;
		margin: 10px 0px;
		border-radius: 12px;

		span {
			.semiMediumFont();
			color: @font-color;
			font-size: 14px;
		}
	}

	.checkBoxTitle {
		span {
			color: #000;
		}

		.ant-checkbox+span {
			padding-right: 25px;
			padding-left: 8px;
			word-wrap: break-word;
		}
	}

	span.checkEmail {
		margin-left: 8px;
	}
}

// --------------------------------------------------------add team members css ends here----------------------------------------------

// ---------------------------------------------------clients css stars from here-----------------------------------------------------------
.clientLayout {
	.ant-page-header-left{
		margin: -4px 25px !important;
    padding-top: 7px !important;
	}
	.ant-col {
		padding: @padding;
		// padding-top: 30px;
	}

	// .gridButton {
	// 	display: flex;
	// 	align-items: center;
	// 	// justify-content: space-between !important;
	// }

	.ant-tag {
		padding: 4px 10px !important;
		font-size: 14px !important;
		margin-right: 0px !important;
	}

	.clientButton {

		// height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;

		.changeStatus {
			margin-left: -18px;
			margin-right: 0px !important;
		}

		.editClient {
			line-height:30px;
			padding: 4px 10px;
			svg {
				transition: @transition;

				&:hover {
					path {
					fill: @primary-color;
				}
			}
		}
	}
		a{
			color: #676A78;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 14px;
	padding-left:10px;
	transition: @transition;
	     &:hover{
            color:@primary-color;
		 }

		}
		.userIcon{
			border: none;
    background: transparent;
    box-shadow: none;
    padding: 0px 0px;
	height: 32px;
    padding: 4px 10px;
	svg{
		transition:@transition;
		&:hover{
			path{
				fill:@primary-color;
			}
		}
	}
	
		}

	}
	.backTo {
		position: absolute;
		left: 60px;
		bottom: 38%;
		cursor: pointer;
        top:27px;  
		&:after {
			position: absolute;
			height: 24px;
			width: 24px;
			border-radius: 50%;
			background: rgba(74, 92, 251, 0.1);
			content: "";
			left: -8px;
		}

		@media screen and (max-width:534px) {
			bottom: 50%;
		}
	}


}

// ---------------------------------------------------client css ends here-------------------------------------------------------------




// --------------------------------------roles css starts from here-------------------------------------------------------------------

.rolesLayout {
	.ant-page-header {
		.ant-page-header-heading {
			// padding: 20px 20px 25px 20px !important;
			box-shadow: 0px 0px 40px 4px rgb(40 43 54 /0.08);

			.ant-page-header-heading-left {
				margin: 2px 35px;
			}
		}

	}

	.inviteNew {
		.ant-space-item {
			padding: 0px 24px;
		}

		button {
			img {
				width: 20px;
				margin-left: 13px;
			}
		}
	}

	.gridButton {
		// .Flex();
		// justify-content: flex-end;
		// flex-wrap: wrap;
		// line-height:4.5;

		button {
			padding: 4px 9px;
			font-size: 20px;
			line-height: 1;
		}

		.editBtn {
			svg {
				transition: @transition;

				&:hover path {
					fill: @primary-color;
				}
			}
		}

		.viewBtn {
			svg {
				transition: @transition;

				&:hover path {
					fill: @primary-color;
				}
			}
		}
	}
}

// .roleList{
// 	overflow:auto;
// }
.ant-collapse {
	border: none;
	background-color: @white;
	overflow: auto;
	height: 200px;
	width: 100%;

	.ant-collapse-content {
		border: none;
	}

	.stackList {
		display: flex;
		flex-direction: column;

		.ant-checkbox-wrapper {
			margin-left: 0px;
			padding: 5px 0px;
		}

	}

	.ant-collapse-item {
		border: none;

		.ant-collapse-header {
			transition: @transition;
			padding: 15px 0px;

			.ant-collapse-header-text {
				.mediumFont();
				font-size: 14px;
			}

			&:hover {
				color: @font-color;
			}

			// @media screen and (max-width:800px) {
			// 	display: block;
			// }
			.ant-collapse-extra {

				// padding:0px 50px;
				.ant-switch {
					margin-left: -80px;
				}
			}

			.ant-collapse-arrow {
				right: 7px;
			}

		}

	}
}

.selectAll {
	display: flex;
	align-items: center;
	justify-content: space-between;

	span {
		color: @font-color;
		.mediumFont();
		font-size: 14px;

	}
}

.addRoleModel {
	.ant-modal-close {
		top: 11px;
		right: 10px;
	}


	.ant-modal-header {
		padding: 12px 24px 0px 12px !important;
	}

	.ant-divider-horizontal {
		margin: 24px 0px 0px 0px;
	}

	.ant-modal-footer {
		.inviteLink {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 15px 0px;
			color: @font-color;
			.mediumFont();
			font-size: 16px;
			transition: @transition;
			cursor: pointer;

			&:hover {
				color: @primary-color;
			}
		}
	}

	.ant-form-item {
		padding-top: 0px !important;
	}

	.ant-select {
		.ant-select-arrow {
			svg {
				path {
					fill: @primary-color;
				}
			}
		}

	}

	.ant-select-focused {
		border: 1px solid @primary-color;
		box-shadow: none;
		border-radius: 12px;

	}

	.ant-collapse-arrow {
		color: @primary-color;
	}

	.ant-collapse-content-box {
		padding: 0px 16px 0px 16px;
	}

	.ant-collapse-arrow {
		top: 75% !important;
	}

	.ant-collapse-header {
		padding: 30px 0px 0px 0px !important;
	}
}

// ----------------------------------------------------templates css starts from here----------------------------------------------------------

.templatesLayout {
	.ant-tabs-nav-wrap {
		padding: 10px 24px 0px 48px !important;
	}

	.templatesCollapse {
		// padding: 10px 0px;

		.ant-collapse {
			height: 100%;
		}

		.ant-collapse-item {
			margin: 20px 24px;
			background-color: #fff;
			border-radius: 10px;
			box-shadow: 0px 0px 40px 4px rgb(40 43 54 /0.08);

			.ant-collapse-header {
				padding: 20px;
			}

		}

		.mainHeading {

			.collapseHeader {
				.mediumFont();
				color: #000;
				font-size: 16px;
			}

			.subHeading {
				display: flex;
				align-items: center;
				// justify-content: space-around;
				width: 7%;

				@media screen and (max-width:800px) {
					width: 100%;
					flex-wrap: wrap;
				}

				.ant-tag {
					margin: 12px 9px;
					background: #4a5cfb33;
					color: @primary-color;
					padding: 4px 10px 4px 10px;
					border-radius: 6px;
					font-size: 14px;
					.mediumFont();
				}

				span {
					.semiMediumFont();
					font-size: 14px;
					color: @font-color;
				}
			}
		}

		.ant-collapse-arrow {
			top: 27% !important;
			right: 25px !important;
		}

		.disabledSwitch {
			.mainHeading {
				width:95%;
				opacity: 0.3;
				
			}
		}
	}

	.extraCollapse {
		// padding:20px 70px;
		padding: 10px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row-reverse;

		.collapseEdit {
			padding: 0px 0px 0px 20px;
			.mediumFont();
			font-size: 14px;
			color: @font-color;
			transition: @transition;

			&:hover {
				color: @primary-color;
				cursor: pointer;
			}

			@media screen and (max-width:800px) {
				padding: 0px 30px 0px 0px;
			}
		}

		@media screen and (max-width:800px) {
			padding: 0px 0px;
		}
	}

	.collapseContent {
		background: #f2f2f2;
		padding: 20px;
		height: 140px;
		border-radius: 12px;
		max-height: 100%;
		overflow: scroll;
	}


	.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		cursor: default;
	}

}

.EditTemplateModel {
	.ant-modal-header {
		padding: 12px 12px 0px 12px !important;
	}

	.ant-modal-close {
		top: 11px;
		right: 10px;
	}

	.ant-modal-body {
		padding: 0px 20px;

		.ant-form-item {

			position: relative;

			.ant-form-item-label {
				padding: 0;
				position: absolute;
				left: 16px;
				top: 3px;
				z-index: 9;
				// .transition;

				label {
					font-size: 12px;
					color: @grey-color;
					font-weight: 400;
					font-family: @fontFamily;
					height: 100%;
				}
			}

			.ant-form-item-control-input {
				.ant-input {

					font-size: 14px;
					color: @default-color;
					font-weight: 600;
					font-family: @fontFamily;
					height: 50px;
					border: none;
					display: flex;
					flex-wrap: wrap;
					padding: 20px 20px 5px 14px !important;
					align-items: center;
					background-color: @input-field-color;
					padding: 0 15px;
					// .transition;
					border-radius: 12px;

					&:focus,
					&:active {
						box-shadow: 0 0 0 1px #4a5cfb;
					}
				}

			}

			.ant-input-status-error {
				background-color: @input-field-color !important;
			}
		}

		.ant-form {
			margin: 10px 0px 20px 0px;
		}

		// .ant-input {
		// 	// padding:10px 20px !important;
		// 	position: relative;
		// 	margin:5px 0px;

		// 	span.ant-input-group-addon {
		// 		background: no-repeat;
		// 		color: @font-color;
		// 		border: 0;
		// 		position: absolute;
		// 		z-index: 9;
		// 		transform-origin: 0 0;
		// 		transition: @transition;
		// 		top: 30%;
		// 		transform: translate(0, -50%);
		// 		// margin: -5px 12px;

		// 		padding-left: 14px;
		// 	}

		// 	input:focus~span.ant-input-group-addon {
		// 		transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
		// 		top: 25px;
		// 		font-size: 12px;
		// 	}
		// }
	}

	.templateData {
		border-radius: 12px;
		background: #F2F3F7;
		border: none;
		padding: 20px 20px 5px 16px;
		.mediumFont();
		font-size: 14px;
		margin-top: 7px;
	}

	.PhoneInput {
		border-radius: 12px;
		background: #F2F3F7;
		border: none;
		/* padding: 20px 20px 5px 16px; */
		font-family: 'Source Sans Pro';
		font-weight: 600;
		font-size: 14px;
		height: 50px;

		.PhoneInputCountryIcon {
			margin-left: 14px;
		}

		.PhoneInputInput {
			background: #f2f3f7;
			padding-right: 10px;
			border: none;
			height: 40px;
			outline: 0;
			font-weight: 600;
		}

	}

	.PhoneInput--focus {
		border: 1px solid @primary-color;
	}
}

// ---------------------------------------------------templates css ends here-------------------------------------------------------------------


// .grid_wrapper,
// .ant-page-header.ant-page-header-ghost {
// 	height: 100%;
// 	min-height: 380px;
// 	padding-left: 0;
// 	padding-right: 0;
// }

.grid_wrapper {
	height: calc(100% - 54px);
}

.main.ant-layout-content.app__container {
	height: calc(100% - 41px);
}

// .breadcrumsHeading{
// 	padding: 10px 30px;
//     min-height: 300px;
//     height: calc(100vh - 80px);
// }
.ag-grid-height {
	height: calc(100vh - 210px) !important;
	margin-top: 30px;
}

.ag-cell {
	&.Custom_Cell {
		line-height: 50px;

		.gridButton {
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}


// ------------------ant -input

.ant-form-item {
	position: relative;
	// margin-bottom: 20px;

	.ant-form-item-label {
		padding: 0;
		position: absolute;
		left: 16px;
		top: 5px;
		z-index: 9;
		// .transition;

		label {
			font-size: 12px;
			color: @grey-color;
			font-weight: 400;
			font-family: @fontFamily;
			height: 100%;
		}
	}

	.ant-form-item-control-input {
		.ant-input {
			font-size: 14px;
			color: @default-color;
			font-weight: 600;
			font-family: @fontFamily;
			height: 50px;
			border: none;
			display: flex;
			flex-wrap: wrap;
			padding: 20px 20px 5px 14px !important;
			align-items: center;
			background-color: @input-field-color;
			padding: 0 15px;
			// .transition;
			border-radius: 12px;

			&:focus,
			&:active {
				box-shadow: 0 0 0 1px #4a5cfb;
			}
		}

	}

	.ant-input-status-error {
		background-color: @input-field-color !important;
	}
}


// --------------------------------referral User css starts from here--------------------------------------------------------------

.referralUserLayout {
	position: relative;

	.ant-page-header {
		.ant-page-header-heading {
			padding: 28px 0px 28px 28px !important;
			box-shadow: 0px 0px 40px 4px rgb(40 43 54 /0.08);

			.ant-page-header-heading-left {
				margin: -4px 60px !important;

				.ant-page-header-back {
					margin-right: 14px;
				}
			}
		}
	}

	.ant-page-header-heading-extra {
		margin: 0px 0px;

		.backTo {
			position: absolute;
			left: 60px;
			bottom: 38%;
			cursor: pointer;

			&:after {
				position: absolute;
				height: 24px;
				width: 24px;
				border-radius: 50%;
				background: rgba(74, 92, 251, 0.1);
				content: "";
				left: -8px;
			}

			@media screen and (max-width:534px) {
				bottom: 50%;
			}
		}
	}

	.userSubscriptionLayout {
		box-shadow: 0px 4px 40px 4px rgb(40 43 54 / 8%);
		margin: 5px 24px;
		padding: 20px 20px;
		border-radius: 10px;

		.userRow {
			justify-content: space-between;
			padding: 0px 0px !important;

			.referralUserBtn {
				.saveBtn {
					background-color: @primary-color;
					color: #fff;
					padding: 8px 40px;
					height: 50px;
					border-radius: 10px;

					span {
						.mediumFont();
						font-size: 16px;
					}
				}
			}

			.subCat {
				display: flex;
				width: 40%;

				.subSelect {
					width: 250px;

					.ant-form-item {
						position: relative;
						margin-bottom: 0px;

						.ant-form-item-label {
							padding: 0;
							position: absolute;
							left: 16px;
							top: 5px;
							z-index: 9;
							// .transition;

							label {
								font-size: 12px;
								color: @grey-color;
								font-weight: 400;
								font-family: @fontFamily;
								height: 100%;
							}
						}

						.ant-form-item-control-input {
							min-height: 50px;
						}

					}

					.ant-select-selector {
						background: @input-field-color;
						height: 50px;
						border-radius: 12px;
						border: none;

						.ant-select-selection-item {
							color: #000;
							font-weight: 600;
							padding: 7px 0px;
						}


					}

					.ant-select-focused {
						border: none;
						box-shadow: 0 0 0 1px #4a5cfb;
						border-radius: 12px;
					}

					.ant-form-item-extra {
						min-height: 24px;
						position: absolute;
						right: 15px;
						top: 25%;
						cursor: pointer;
					}
				}

				.subDate {
					width: 250px;
					margin: 0px 20px;

					.ant-picker {
						width: 100%;
						height: 50px;
						border-radius: 12px;
						background-color: #F2F3F7;
						border: none;
					}

					.ant-picker-focused {
						box-shadow: 0 0 0 1px #4a5cfb;
					}
				}
			}

		}


		.standardLayout {
			margin-top: 20px;

			.standardCard {
				background: rgba(74, 92, 251, 0.1);
				// opacity: 0.1;
				border-radius: 10px;
				height: 440px;
				max-height: 440px;
				margin-left: 40px;
                  .cradContent {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					flex-wrap: nowrap;

					.subscriptionDetails {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						flex-wrap: wrap;
						margin-top:10px;

						h4 {
							.semiMediumFont();
							color: @default-color;
							font-size: 20px;
							margin-bottom: 5px;
						}
						.freeMsg{
							display: flex;}
						.subMontly {
							display: flex;
							align-items: center;
							margin-bottom: 0px;

							h3 {
								font-weight: 700;
								color: @default-color;
								font-family: @font-family;
								font-size: 26px;
								margin-bottom:0px;
							}

							span {
								color: @font-color;
								font-size: 16px;
								.semiMediumFont();
								padding-left: 5px;
								
							}
						}
					
						.premiumMonth {
							position: relative;

							h3 {
								color: @font-color !important;
								.semiMediumFont() !important;

							}

							&::before {
								content: '';
								border-bottom: 1px solid #676A78;
								width: 100%;
								position: absolute;
								right: 0;
								top: 40%;
							}

							span {
								color: #676A78;
								font-size: 16px;
								.semiMediumFont();
								padding-left: 5px;
								padding-bottom: 8px
							}
						}

						.subFeatures {
							margin: -20px 0px 0px 0px;
							padding:22px 0px;

							div {
								// padding: 10px 0px;
								line-height:2.5;
							

								span {
									padding-left: 14px;
									.semiMediumFont();
									font-size: 14px;
									// line-height:3;
								}
							}
						}
						.sixMonthOffer{
							color:#4A5CFB;
							font-size: 14px;
							font-family:"Source Sans Pro";
							font-weight:600;
							padding:0px 0px 10px 0px;
						}

					}
				}
			

			}

			.ant-divider {
				height: 31.5em;
				border-left: 1px solid @border-color;
				margin: 0px 20px;

			}

			.premiumCard {
				border-radius: 10px;
				height: 440px;
				max-height: 440px;
				border: none;
				margin-left: 40px;

				.cradContent {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					flex-wrap: nowrap;

					.subscriptionDetails {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						flex-wrap: wrap;
						margin-top: 15px;

						h4 {
							.semiMediumFont();
							color: @default-color;
							font-size: 20px;
							margin-bottom:5px;
						}

						.subMontly {
							display: flex;
							align-items: center;
							margin-bottom: 0px;

							h3 {
								font-weight: 700;
								color: @default-color;
								font-family: @font-family;
								font-size: 26px;
								margin-bottom: 0px;
							}

							span {
								color: @font-color;
								font-size: 16px;
								.semiMediumFont();
								padding-left: 5px;
							
							}
						}


						.subFeatures {
							padding: 15px 0px;
							div {
								line-height:2.5;

								span {
									padding-left: 14px;
									.semiMediumFont();
									font-size: 14px;
								}
							}
						}

					}
				}
				.sixMonthOffer{
					color:#4A5CFB;
					font-size: 14px;
					font-family:"Source Sans Pro";
					font-weight:600;
				}

			}

		}

		.premiumDeadLine {
			.ant-card {
				height: 500px;
				max-height: 500px;
			}

			.ant-card-body {
				height: 500px;

				.subscriptionEnds {
					h3 {
						color: @font-color;
						.mediumFont();
						font-size: 20px;
						margin-top: 10px;
					}

					.endDate {
						background-color: #fff;
						text-align: center;
						padding: 8px 0px;
						border-radius: 10px;
						margin-bottom: 20px;

						span {
							.mediumFont();

							font-size: 24px;
						}
					}

					.endMsg {
						width: 65%;

						span {
							.semiMediumFont();
							font-size: 16px;

						}
					}
				}
			}
			.sixMonthOffer{
				color:#4A5CFB;
				font-size: 14px;
				font-family:"Source Sans Pro";
				font-weight:600;
				padding:0px 0px 10px 0px;
			}
		}

		.ag-grid-wrapper {
			padding: 0px 0px;
			margin: 0px 0px;

			.ag-root-wrapper {
				box-shadow: none;
			}
		}

		.listHeading {
			.mediumFont();
			font-size: 16px;
		}

	}
}