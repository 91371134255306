@import "../variables.less";

/* Let's get this party started */
::-webkit-scrollbar {
	width           : 5px;
	background-color: transparent;
	border          : 0;
}

/* Track */
::-webkit-scrollbar-track {
	-webkit-box-shadow   : inset 0 0 6px rgba(0, 0, 0, 0.01);
	box-shadow           : inset 0 0 6px rgba(0, 0, 0, 0.01);
	-webkit-border-radius: 10px;
	border-radius        : 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius        : 10px;
	background           : #e2e2e2;
	-webkit-box-shadow   : inset 0 0 6px rgba(0, 0, 0, 0.01);
	box-shadow           : inset 0 0 6px rgba(0, 0, 0, 0.01);
}

::-webkit-scrollbar-thumb:window-inactive {
	background: #e2e2e2;
}

.main__page__appsidebar {

	flex      : 0 0 200px;
	max-width : 200px;
	min-width : 200px;
	width     : 200px;
	background: @white;
	position  : fixed;
	// overflow-y: auto;
	height    : 100%;
	// overflow-x: hidden;
	&:after {
		position        : absolute;
		content         : "";
		background-color: @border-color;
		width           : 1px;
		height          : 100%;
		right           : 0px;
		top             : 0;
	}

	.ant-menu {
         background-color: @white;
		 padding-bottom: 20px;
		transition:@transition;
	.ant-menu-sub {
			background:#f9f9fc;
			// padding:15px 0px;
			padding-bottom:0px;
			padding:5px 0px;
			transition:0.9s all linear;
			.ant-menu-item{
				margin:5px 0px;
			}
			.ant-menu-title-content {
				padding-left:30px !important;
			}
		}

		.ant-menu-item{
			padding   : 13px 0px !important;
			transition: @transition;

			&:before {
				background-color          : @primary-color;
				position                  : absolute;
				left                      : 0;
				content                   : "";
				height                    : 100%;
				border-top-right-radius   : 30px;
				width                     : 4px;
				border-bottom-right-radius: 30px;
				opacity                   : 0;
				transition                : @transition;
				
			}

			.ant-menu-title-content {
				color       : @font-color;
				transition  : @transition;
				padding-left: 13px;
				border-left : 3px solid transparent;
				font-family : @fontFamily;
				position    : relative;
				font-weight : 600;
				font-size   : 14px;
		       }

			&:hover {
					background-color: rgba(74, 92, 251, 0.05);
		
					// &::before{
					// 	opacity: 1;
						
					// }
					.ant-menu-title-content {
						color : #000;
					}
				
				}
			
			}

			.ant-menu-item-selected{
			
					&::before{
						opacity: 1;
					}
					.ant-menu-title-content {
						color : #000;
					}
			
			}
	
	
		
		.ant-menu-dark.ant-menu-item-selected{
			color           : #000;
			background-color: rgba(74, 92, 251, 0.05);
	

		}

	
	}
	.ant-menu-submenu{
		padding   : 0px 0px !important;
	// transition: @transition;
	
	.ant-menu-submenu-title{
		padding-left:0px !important;
		padding-right:0px !important;

		span{
		color       : @font-color;
		transition  : @transition;
		padding-left: 13px;
		border-left : 3px solid transparent;
		font-family : @fontFamily;
		position    : relative;
		font-weight : 600;
		font-size   : 14px;
		
	}
	&:hover{
		background-color: rgba(74, 92, 251, 0.05);
	.ant-menu-title-content {
	   color : #000;
					}
	}
	}
	.ant-menu-sub{
   .ant-menu-title-content{
	position: relative;
	&:before {
		background-color          : @primary-color;
		position                  : absolute;
		left                      : 0;
		content                   : "";
		height                    : 100%;
		border-top-right-radius   : 30px;
		width                     : 0px;
		border-bottom-right-radius: 30px;
		opacity                   : 0;
		transition                : @transition;
	}
	&:hover {
		&:before {
			opacity: 1;
		}
	}
	&:active{
		&::before{
			opacity: 1;
		}
	}
 }
	}
   .ant-menu-submenu-arrow{
	&:before{
	background-color:@font-color !important;
	}
	&::after{
		background-color:@font-color !important;
	}
   }


}
.ant-menu-submenu-open{
	color: #000;
	background-color: rgba(74, 92, 251, 0.05);
	.ant-menu-submenu-title{
		
		span{
		position: relative;
		&:before {
			background-color          : @primary-color;
			position                  : absolute;
			left                      : 0;
			// content                   : "";
			height                    : 100%;
			border-top-right-radius   : 30px;
			width                     : 4px;
			border-bottom-right-radius: 30px;
			opacity                   : 1;
			transition                : @transition;
		}
	} 
	&:hover{
		&::before{
			opacity: 1;
		}
	}
	   }

}
.ant-menu-submenu-inline{
	transition:@transition;
}
	&.ant-layout-sider-collapsed {
		.logo__wrapper {
		
			span {
				display: none;
			}
			
		}
		@media screen and (max-width:768px) {
			flex:0 0 0px;
			width:0px;
			min-width:0px;
			max-width:0px;
		}
	}

	.logo__wrapper {
		position: relative;
		border:none;
		height : @logo-height;
		// padding: 10px @gutter;
		// padding      : 0px 11px 0px 11px;
		padding: 10px 20px;
		margin-top   : 6px;
	    //  margin-bottom: 10px;

		span {
			color: @white;
		}
		&:after{
			position: absolute;
			height: 1px;
			width: 100%;
			bottom: 5px;
			left: 0;
			content: "";
			background-color:@border-color;
		}
        img{
			margin-left:0;
		}
	}

	.sideBottomIcons {
		padding       : 0px 0px 0px 5px;
		position: absolute;
		bottom: 0;
		width: 100%;
		background:@white;

		a {
			display         : flex;
			// margin-bottom:8px;
			margin          : 17px;
			color           : @font-color;
			font-family     : @fontFamily;
			font-weight     : 600;
			align-items     : center;
			transition      : @transition;

		  &:hover{
				svg{
					display    : flex;
					align-items: center;
				
	
					path {
						&:hover {
							fill: @primary-color;
						}
					}
				}
				color: @primary-color;
			}
			svg{
				transition      : @transition;
			}
		}
		span{
			padding-left:15px;
		}
		&:hover{
			svg{
				path{
					fill: @primary-color;
				}
			}
			span{
				color:@primary-color;
			}
		}

	}
	.sideBarMenu {
		height: calc(100% - 138px);
		overflow: auto;
		padding-top:25px;
	}

}

.ant-menu-dark {

		.ant-menu-item-selected {
			background-color: rgba(74, 92, 251, 0.05) !important;}
		// 	.ant-menu{
		// 	// &:before {
		// 	// 	background-color          : @primary-color;
		// 	// 	position                  : absolute;
		// 	// 	left                      : 0;
		// 	// 	content                   : "";
		// 	// 	height                    : 100%;
		// 	// 	border-top-right-radius   : 30px;
		// 	// 	width                     : 4px;
		// 	// 	border-bottom-right-radius: 30px;
		// 	// 	opacity                   : 0;
		// 	// 	transition                : @transition;
		// 	// }
		// }
		
	}



.ant-menu-submenu-popup {

	.ant-menu-vertical.ant-menu-sub,
	.ant-menu-vertical-left.ant-menu-sub,
	.ant-menu-vertical-right.ant-menu-sub {
		max-height: initial;
		background: darken(@layout-sidebar-background, 3%);
	}
}


