// font family, font-weight:600
.mediumFont{
    font-family:@fontFamily;
    font-weight:600;
}

// grid font------------
.semiMediumFont{
    font-family:@fontFamily;
    font-weight:400;
}

// for flex-property-------------------
.Flex{
    display:flex;
    align-items:center;
    
}
