.main__page__header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	// border-bottom: 1px solid @border-color-base;

	.container,
	.container-fluid {
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.header__menu {
		&__item {
			font-size: 16px;
		}
	}
}
